import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
   * {
       margin: 0;
       padding: 0;
       outline: 0;
       box-sizing: border-box;
       font-family: 'Open Sans', sans-serif; 
   }

   #root {
       margin: 0 auto;
   }

   body {
    width: 100vw;
    height: 100vh;
    background-color: #ccc;
   }
`;
